import React from 'react';
import '../assets/styles/Veille.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPodcast, faBell, faLink, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

const Veille = () => {
    return (
        <div className="veille-container" id="veille">
            <h2>Veille Technologique</h2>
            <p>Voici une sélection de sujets et d'articles pour suivre les dernières avancées technologiques.</p>

            {/* Section Podcasts */}
            <div className="veille-section">
                <h3>Podcasts</h3>
                <div className="veille-item">
                    <FontAwesomeIcon icon={faPodcast} size="2x" />
                    <div>
                        <p>
                            <a href="https://podcasts.apple.com/fr/podcast/underscore/id1556250107" target="_blank" rel="noopener noreferrer">
                               Underscore_ - Actualités code / cybersécurité / IA
                            </a>
                            <a href="https://podcasts.apple.com/fr/podcast/underscore/id1556250107" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: '8px' }} />
                            </a>
                        </p>
                        <p>
                            <a href="https://techcafe.fr/" target="_blank" rel="noopener noreferrer">
                                Tech Café - Actualités domotique / nouvelles normes / nouveaux produits
                            </a>
                            <a href="https://techcafe.fr/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: '8px' }} />
                            </a>
                        </p>
                    </div>
                </div>
            </div>

            {/* Section Veille Passive */}
            <div className="veille-section">
                <h3>Veille passive</h3>
                <div className="veille-item">
                    <FontAwesomeIcon icon={faBell} size="2x" />
                    <div>
                        <p>
                            <a href="https://www.google.fr/alerts" target="_blank" rel="noopener noreferrer">
                                Google Alerts - Vulnérabilités automates / failles zero days / différents constructeurs
                            </a>
                            <a href="https://www.google.fr/alerts" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: '8px' }} />
                            </a>
                        </p>
                        <p>Mise en place depuis plus d'un an</p>
                    </div>
                </div>
            </div>

            {/* Section Veille Active */}
            <div className="veille-section">
                <h3>Veille active</h3>
                <div className="veille-item">
                    <FontAwesomeIcon icon={faLink} size="2x" />
                    <div>
                        <p>
                            <a href="https://www.linkedin.com/company/anssi-fr/posts/?feedView=all" target="_blank" rel="noopener noreferrer">
                                LinkedIn - Veilles sur les CVE / Seniors en cybersécurité
                            </a>
                            <a href="https://www.linkedin.com/company/anssi-fr/posts/?feedView=all" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: '8px' }} />
                            </a>
                        </p>
                        <p>
                            <a href="https://thehackernews.com/" target="_blank" rel="noopener noreferrer">
                                Actualités Cyber 
                            </a>
                            <a href="https://thehackernews.com/" target="_blank" rel="noopener noreferrer">
                                <FontAwesomeIcon icon={faExternalLinkAlt} style={{ marginLeft: '8px' }} />
                            </a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Veille;
